import axios from 'axios';
import { IDivision } from '../../../@types/model/masterData/division/division';
import { IDivisionUpsert } from '../../../@types/model/masterData/division/divisionUpsert';
import qs from 'qs';

export default class DivisionHttpService {
    public static divisionGetList = async (divisionIds ?: Array<number>) : Promise<Array<IDivision>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Division/GetList`, {
            params: {
                divisionIds,
            },
            paramsSerializer: qs.stringify,
        });
        return result.data;
    }

    public static divisionUpsert = async (divisionUpsert : IDivisionUpsert) : Promise<IDivision> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Division/Upsert`, divisionUpsert);
        return result.data;
    }

    public static divisionDelete = async (divisionId : number) : Promise<void> => {
        await axios.delete(`${API_URL}/v1/MasterData/Division/Delete`, {
            params: { divisionId },
        });
    }
}