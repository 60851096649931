import { Button, IconButton, Typography } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfirmationDialog, CustomTooltip, GoogleSignInButton, IGoogleLoginError, IGoogleLoginSuccess, IOptionType, MuiAutocompleteSelect, PillButton, ProjectDialog } from '@zz2/zz2-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { IUser } from '../../@types/model/auth/user/user';
import { OptionType } from '../../@types/model/optionType';
import { useAppDispatch } from '../../@types/redux';
import { useGetDepartments } from '../../hooks/query/masterData/departmentQueries';
import { useGetDivisions } from '../../hooks/query/masterData/divisionQueries';
import { useHandleLinkGoogleAccount, useHandleUnlinkGoogleAccount } from '../../hooks/query/users/userQueries';
import { getUserSelectedDepartmentsLocalStorage, getUserSelectedDivisionsLocalStorage, setUserSelectedDepartmentsLocalStorage, setUserSelectedDivisionsLocalStorage } from '../../service/localStorageService';
import DataActions from '../../store/data/actions';
import GeneralThunks from '../../store/general/thunk';

interface IUserSettingsProps {
    isOpen : boolean;
    setIsUserDialogOpenCallback : (state : boolean) => void;
    currentUser : IUser | undefined;
    onClose : () => void;
    signOut : () => void;
}

const UserSettings = (props : IUserSettingsProps) : React.ReactElement => {
    const dispatch = useAppDispatch();

    const { isLoading: isDivisionsLoading, data: divisions } = useGetDivisions();
    const { isLoading: isDepartmentsLoading, data: departments } = useGetDepartments();
    const { isLoading: isUnlinkingGoogleAccount, mutate: unlinkGoogleAccount } = useHandleUnlinkGoogleAccount();
    const { isLoading: isLinkingGoogleAccount, mutate: linkGoogleAccount } = useHandleLinkGoogleAccount();

    const selectedDivisions = getUserSelectedDivisionsLocalStorage();
    const selectedDepartments = getUserSelectedDepartmentsLocalStorage();

    const [selectedDivisionOptions, setSelectedDivisionOptions] = useState<Array<IOptionType>>([]);
    const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState<Array<IOptionType>>([]);
    const [userSettingsChanged, setUserSettingsChanged] = useState<boolean>(false);
    const [isUnlinkGoogleAccountDialogOpen, setIsUnlinkGoogleAccountDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        loadData();
    }, [props.isOpen, divisions, departments]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const loadData = () : void => {
        if (props.currentUser?.divisionIds && props.currentUser.divisionIds.length > 0) {
            const selectedDivisionOptions = divisions?.filter(div => selectedDivisions.some(localSelectedDiv => div.id === localSelectedDiv.value))
                .map(OptionType.fromDivision) ?? [];

            if (selectedDivisions.length < 1) return;

            setSelectedDivisionOptions(selectedDivisionOptions);
        }

        if (props.currentUser?.departmentIds && props.currentUser.departmentIds.length > 0) {
            const selectedDepartmentOptions = departments?.filter(dept => selectedDepartments.some(localSelectedDept => dept.id === localSelectedDept.value))
                .map(OptionType.fromDepartment) ?? [];

            if (selectedDepartments.length < 1) return;

            setSelectedDepartmentOptions(selectedDepartmentOptions);
        }
    };

    const confirmUserSettingsUpdate = () : void => {
        if (selectedDivisionOptions.length < 1 || selectedDepartmentOptions.length < 1) {
            dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'Select at least one division and department.' }));
            return;
        }        

        setUserSelectedDivisionsLocalStorage(selectedDivisionOptions);
        dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisionOptions.map(x => x.value)));
        setUserSelectedDepartmentsLocalStorage(selectedDepartmentOptions);
        dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartmentOptions.map(x => x.value)));

        setUserSettingsChanged(false);
        props.onClose();
    };    

    const closeUserSettings = () : void => {
        props.onClose();
        setUserSettingsChanged(false);
    };

    const onDivisionChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDivisionOptions(selectedOptions);
        setSelectedDepartmentOptions([]);
        setUserSettingsChanged(true);
    };

    const onDepartmentChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDepartmentOptions(selectedOptions);
        setUserSettingsChanged(true);
    };

    const onDivisionSelectAll = () : void => {
        if (divisionOptions.length !== selectedDivisionOptions.length) {
            onDivisionChange(divisionOptions);
        } else {
            onDivisionChange([]);
        }
        setUserSettingsChanged(true);
    };

    const onDepartmentSelectAll = () : void => {
        if (departmentOptions.length !== selectedDepartmentOptions.length) {
            onDepartmentChange(departmentOptions);
        } else {
            onDepartmentChange([]);
        }
        setUserSettingsChanged(true);
    };

    const onGoogleAccountLinkSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        linkGoogleAccount({ code: response.code });
    };

    const onGoogleAccountLinkFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Google Account linking failed.',
            ex: response,
        }));
    };

    const onUnlinkGoogleAccount = () : void => {
        if (!props.currentUser)
            return;

        unlinkGoogleAccount();
        closeUnlinkGoogleAccountDialog();
    };

    const openUnlinkGoogleAccountDialog = () : void => setIsUnlinkGoogleAccountDialogOpen(true);

    const closeUnlinkGoogleAccountDialog = () : void => setIsUnlinkGoogleAccountDialogOpen(false);

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const isLoading = useMemo<boolean>(() => {
        return isDivisionsLoading 
        || isDepartmentsLoading
        || isLinkingGoogleAccount
        || isUnlinkingGoogleAccount;
    }, [isDivisionsLoading, 
        isDepartmentsLoading,
        isLinkingGoogleAccount,
        isUnlinkingGoogleAccount
    ]);

    const divisionOptions = useMemo<Array<IOptionType>>(() => {
        if (!divisions || !props.currentUser || !props.currentUser.divisionIds) return [];

        return divisions
            .filter(div => div.isActive && (props.currentUser?.divisionIds?.some(divId => div.id === divId)))
            .map(OptionType.fromDivision);
    }, [divisions, props.currentUser]);

    const departmentOptions = useMemo<Array<IOptionType>>(() => {
        if (!departments || !props.currentUser || !props.currentUser.departmentIds) return [];

        return departments
            .filter(dept => dept.isActive   
                && (props.currentUser?.departmentIds?.some(deptId => dept.id === deptId))
                && selectedDivisionOptions.some(div => dept.subdivision?.divisionId === div.value))
            .map(OptionType.fromDepartment);
    }, [departments, props.currentUser, selectedDivisionOptions]);    

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <>
            <ProjectDialog
                title={'User Settings'}
                isOpen={props.isOpen}
                isLoadingCircular={isLoading}
                fullWidth
                maxWidth={'xs'}
                onConfirm={confirmUserSettingsUpdate}
                disableConfirm={!userSettingsChanged || selectedDivisionOptions.length == 0 || selectedDepartmentOptions.length == 0}
                onClose={closeUserSettings}
                closeToolTip={(selectedDivisionOptions.length < 1 || selectedDepartmentOptions.length < 1) ? 'Please select at least one division and department.' : ''}
                disableClose={selectedDivisionOptions.length < 1 || selectedDepartmentOptions.length < 1}>
                <div className={'flx1 fdc aic jcc m10'}>
                    <MuiAutocompleteSelect
                        isMulti
                        className={'w300 mt30'}
                        name={'divisions'}
                        label={'Divisions'}
                        options={divisionOptions}
                        value={selectedDivisionOptions}
                        onChange={onDivisionChange}
                    />
                    <Button size={'small'} onClick={onDivisionSelectAll} disabled={divisionOptions.length < 1}>
                        {(divisionOptions.length > 0 && divisionOptions.length === selectedDivisionOptions.length) ? 'Unselect All' : 'Select All'}
                    </Button>
                    <MuiAutocompleteSelect
                        isMulti
                        className={'w300 mt30 mxh200 oya'}
                        name={'departments'}
                        label={'Departments'}
                        options={departmentOptions}
                        value={selectedDepartmentOptions}
                        onChange={onDepartmentChange}
                    />
                    <Button size={'small'} onClick={onDepartmentSelectAll} disabled={departmentOptions.length < 1}>
                        {(departmentOptions.length > 0 && departmentOptions.length === selectedDepartmentOptions.length) ? 'Unselect All' : 'Select All'}
                    </Button>
                    <div className={'fdc aic'}>
                        {
                            (!props.currentUser?.email || !props.currentUser.profilePictureURL)
                                && <div className={'zi1'}> 
                                    <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                        <GoogleSignInButton          
                                            onGoogleSignInSuccess={onGoogleAccountLinkSuccess}
                                            onGoogleSignInFailure={onGoogleAccountLinkFailure}   
                                            buttonText={`Link ${(!!props.currentUser?.email && !props.currentUser.profilePictureURL) ? 'Profile Picture' : 'Google Account'}`}   
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                        }
                        {
                            !!props.currentUser?.email
                            && <div className={'fdc aic jcc'}>
                                <div className={'fdr aic jcc'}>
                                    <Typography className={'fdr m10'}>Linked Email:</Typography>
                                    <Typography variant={'body1'} className={'fdr fwb cpd m10'}>{ props.currentUser.email }</Typography>
                                    <CustomTooltip title={'Unlink Email'}>
                                        <IconButton className={'cr'} onClick={openUnlinkGoogleAccountDialog}>
                                            <ClearIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={'fdr aic pb10 mt20'}>
                        <PillButton
                            className={'h35 w150'}
                            text={'LOGOUT'}
                            color={'secondary'}
                            onClick={props.signOut}
                        />
                    </div>
                </div>
            </ProjectDialog>
            <ConfirmationDialog 
                open={isUnlinkGoogleAccountDialogOpen}
                title={'Unlink Email'}
                description={'Are you sure you would like to unlink the email currently associated with your account?'}
                onAccept={onUnlinkGoogleAccount}
                onClose={closeUnlinkGoogleAccountDialog}
                dialogType={'orange'}
            />
        </>
    );
};

export default UserSettings;