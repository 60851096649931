import axios from 'axios';
import { IDepartment } from '../../../@types/model/masterData/department/department';
import { IDepartmentUpsert } from '../../../@types/model/masterData/department/departmentUpsert';
import qs from 'qs';

export default class DepartmentHttpService {
    public static getList = async (departmentIds ?: Array<number>) : Promise<Array<IDepartment>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Department/GetList`, {
            params: {
                departmentIds,
            },
            paramsSerializer: qs.stringify
        });
        return result.data;
    }

    public static upsert = async (departmentUpsert : IDepartmentUpsert) : Promise<IDepartment> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Department/Upsert`, departmentUpsert);
        return result.data;
    }

    public static delete = async (departmentId : number) : Promise<void> => {
        return axios.delete(`${API_URL}/v1/MasterData/Department/Delete`, {
            params: {
                departmentId,
            },
        });
    }
}
