/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryDeleteSuccessHandler, queryErrorHandler, querySuccessHandler } from '../queryStateHandlers';
import { IDivision } from '../../../@types/model/masterData/division/division';
import { IDivisionUpsert } from '../../../@types/model/masterData/division/divisionUpsert';
import DivisionHttpService from '../../../service/http/masterData/divisionHttpService';
import { GET_DIVISIONS_KEY, UPSERT_DIVISION_KEY, DELETE_DIVISION_KEY } from '../queryConstants';

export const useGetDivisions = (divisionIds ?: Array<number>) => {
    const query = useQuery<Array<IDivision>, Error>({
        queryKey: [GET_DIVISIONS_KEY, divisionIds],
        queryFn: () => DivisionHttpService.divisionGetList(divisionIds),
        onError: queryErrorHandler('Error loading Divisions.'),
    });

    return query;
};

export const useHandleUpsertDivision = () => {
    const result = useMutation<IDivision, Error, IDivisionUpsert>({
        mutationKey: [UPSERT_DIVISION_KEY],
        mutationFn: (upsert : IDivisionUpsert) => DivisionHttpService.divisionUpsert(upsert),
        onError: queryErrorHandler('Error creating/updating Division.'),
        onSuccess: querySuccessHandler(GET_DIVISIONS_KEY, 'Division updated successfully.')
    });

    return result;
};

export const useHandleDeleteDivision = () => {
    return useMutation<void, Error, number>({
        mutationKey: [DELETE_DIVISION_KEY],
        mutationFn: (divisionId : number) => DivisionHttpService.divisionDelete(divisionId),
        onError: queryErrorHandler('Error deleting Division.'),
        onSuccess: queryDeleteSuccessHandler(DELETE_DIVISION_KEY, GET_DIVISIONS_KEY, 'Division inactivated successfully.')
    });
};
